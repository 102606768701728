import { InjectedConnector } from "@web3-react/injected-connector";
import { NetworkConnector } from "@web3-react/network-connector";
import { currentNetwork } from "./index"
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { ConnectorNames, RPC_URLS } from "config";

export const networkConnector = new NetworkConnector({
  urls: RPC_URLS,
  defaultChainId: parseInt(currentNetwork)
})

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [parseInt(currentNetwork)],
});

export const walletConnector = new WalletConnectConnector({
  rpc: RPC_URLS,
  chainId: parseInt(currentNetwork),
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true
})

export const CoinbaseWallet = new WalletLinkConnector({
  url: RPC_URLS[currentNetwork],
  appName: "Coinbase Wallet",
  appLogoUrl: "https://images.ctfassets.net/q5ulk4bp65r7/1rFQCqoq8hipvVJSKdU3fQ/21ab733af7a8ab404e29b873ffb28348/coinbase-icon2.svg",
  supportedChainIds: [parseInt(currentNetwork)],
});

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injectedConnector,
  [ConnectorNames.Coinbase]: CoinbaseWallet,
  [ConnectorNames.WalletConnect]: walletConnector
};
