

export enum ChainId {
    MAINNET = 1,
    GOERLI = 5
}

export const RPC_URLS = {
    1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    5: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
};

export const BLOCK_EXPLORER_URLS: { [key in ChainId]: string } = {
    1: 'https://etherscan.io/',
    5: 'https://goerli.etherscan.io/'
}

export const ConnectorLocalStorageKey = "YASHA-CONNECTOR-ID";

export enum ConnectorNames {
    Injected = "injected",
    Coinbase = "coinbase",
    WalletConnect = "walletconnect"
}
