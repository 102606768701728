import { useStyles } from './style';
import { useContext, useEffect, useState } from 'react';
import MultiLanguageContext from 'context/MultiLanguageContext';

import { useHistory } from 'react-router-dom';
import Faqs from 'components/Sections/Faq/Faqs';
import Loader from 'components/Loader';
import multiText from './project_lang.json';
import { baseUploadUrl, currentNetwork, numberToString, truncateWalletString } from 'utils';
import moment from 'moment';
import { scGetTokenBalance, scGetTokenTotalSupply } from 'utils/contracts';

import { apiGetICOInfo, CancelICOSign, ClaimFromICOSign, ClaimFundTokenFromICOSign, CompleteICOSign, ContributeToICOSign, DepositICOTokenSign, ICOData, RefundFromICOSign, apiPostAllowUsersToClaim, apiPostCancelICO, apiPostClaimFromICO, apiPostClaimFundTokenFromICO, apiPostCompleteICO, apiPostContributeToICO, apiPostDepositICOToken, apiPostRefundFromICO, TierData, apiGetUserInfoForICO, UserInfo, apiPostUpdateICO, apiPostUpdateICOWhitelist, apiGetWhitelistForICO } from 'utils/api';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { scCancelICO, scClaimFromICO, scClaimFundFromICO, scCompleteICO, scContributeToICO, scDepositICOToken, scRefundFromICO } from 'utils/contracts';
import FilledButton from 'components/Buttons/FilledButton';
import LoadingCtx from 'context/LoadingProvider';
import Modal from 'components/modal';
import TextInput from 'components/Forms/TextInput';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { arrayify, hashMessage } from 'ethers/lib/utils';
import UploadFile from 'components/Forms/UploadFile';
import TierInputCard from 'components/Cards/TierInputCard/TierInputCard';
import DateTimePickerField from 'components/DateTimePicker';
import ErrorAlert from 'components/Widgets/ErrorAlert';
import UploadCSV from 'components/Forms/UploadCSV';
import WhiteListTable from 'components/Sections/WhiteListTable/WhiteListTable';
import MySwitch from 'components/Forms/MySwitch';

const ProductDetail = () => {
  const classes = useStyles();
  const { langType } = useContext(MultiLanguageContext)
  const [uiText, setUiText] = useState(multiText[0]);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);
  useEffect(() => {
    if (langType === 'en') setUiText(multiText[0]);
    if (langType === 'cn') setUiText(multiText[1]);
    if (langType === 'es') setUiText(multiText[2]);
    if (langType === 'fr') setUiText(multiText[3]);
    if (langType === 'hi') setUiText(multiText[4]);
    if (langType === 'ja') setUiText(multiText[5]);

  }, [langType]);

  const [item, setItem] = useState<ICOData>(null);
  const [tierTokenBalance, setTierTokenBalance] = useState('');
  const [userInfoForICO, setUserInfo] = useState<UserInfo>(null);
  const [totalSupply, setTotalSupply] = useState('');
  const [whitelist, setWhitelist] = useState([]);
  const [showWhitelist, setShowWhitelist] = useState(false);
  const navigation = useHistory();

  const [loginStatus, setLoginStatus] = useState(false);
  const { connector, library, chainId, account, active } = useWeb3React();

  useEffect(() => {
    const isLoggedin = account && active && chainId === parseInt(currentNetwork, 10);
    setLoginStatus(isLoggedin);
  }, [connector, library, account, active, chainId]);

  useEffect(() => {
    const id = navigation.location.pathname.split('/')[2]
    async function fatchData() {
      try {
        const icoInfo = await apiGetICOInfo(id);
        setItem(icoInfo)

        const supply = await scGetTokenTotalSupply(icoInfo?.icoToken);
        setTotalSupply(numberToString(parseInt(supply)));

        if (loginStatus) {
          const tierBalance = await scGetTokenBalance(icoInfo?.tierToken, account);
          setTierTokenBalance(tierBalance);

          const userInfo = await apiGetUserInfoForICO(icoInfo?._id, account);
          setUserInfo(userInfo)
        }
      } catch (error) {
        console.error(error);
        setItem(null);
      }
    }
    fatchData();
  }, [navigation.location.pathname, account, loginStatus]);


  useEffect(() => {

    async function fatchData() {
      try {


        // if (item?._id && item?._id !=='' ) {
          if (item?._id && item?._id !=='' && item?.admin?.toLowerCase() === account?.toLowerCase()) {
          const whiteArray = await apiGetWhitelistForICO(item?._id);
          setWhitelist(whiteArray)
        }

      } catch (error) {
        console.error(error);
        setWhitelist(null);
      }
    }
    fatchData();

  }, [item, account]);


  const { setLoading } = useContext(LoadingCtx);

  const [depositAmount, setDepositAmount] = useState(0);
  const [depositModal, setDepositModal] = useState(false);
  const [contributeAmount, setContributeAmount] = useState(0);
  const [contributeModal, setContributeModal] = useState(false);
  const [isProccessing, setIsProccessing] = useState(false);

  const depositICOToken = async (amount: number) => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }
    if (account !== ethers.utils.getAddress(item.admin)) {
      toast.error("You are not admin!");
      return;
    }
    setLoading(true);
    setIsProccessing(true);
    try {
      const signData: DepositICOTokenSign = await apiPostDepositICOToken(item._id, amount);
      if (signData) {
        const bSuccess = await scDepositICOToken(chainId, library.getSigner(), account, signData);
        setLoading(false);
        setIsProccessing(false)
        if (bSuccess) {
          toast.success("ICO token deposited successfully!");
        } else {
          toast.error("ICO token deposit failed!");
        }
      } else {
        toast.error("Failed from Api!");
        setLoading(false);
        setIsProccessing(false)
        return;
      }

    } catch (error) {
      console.log(error);
      toast.error("Failed!");
      setLoading(false);
      setIsProccessing(false)
      return;
    }
  }

  const completeICO = async () => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }
    if (account !== ethers.utils.getAddress(item.admin)) {
      toast.error("You are not admin!");
      return;
    }
    setLoading(true);
    try {
      const signData: CompleteICOSign = await apiPostCompleteICO(item._id);
      if (signData) {
        const bSuccess = await scCompleteICO(chainId, library.getSigner(), signData);
        setLoading(false);
        if (bSuccess) {
          toast.success("ICO completed successfully!");
        } else {
          toast.error("ICO complete failed!");
        }
      } else {
        toast.error("Failed from Api!");
        setLoading(false);
        return;
      }

    } catch (error) {
      console.log(error);
      toast.error("Failed!");
      setLoading(false);
      return;
    }
  }

  const cancelICO = async () => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }
    if (account !== ethers.utils.getAddress(item.admin)) {
      toast.error("You are not admin!");
      return;
    }
    setLoading(true);
    try {
      const signData: CancelICOSign = await apiPostCancelICO(item._id);
      if (signData) {
        const bSuccess = await scCancelICO(chainId, library.getSigner(), signData);
        setLoading(false);
        if (bSuccess) {
          toast.success("ICO cancelled successfully!");
        } else {
          toast.error("ICO cancel failed!");
        }
      } else {
        toast.error("Failed from Api!");
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed!");
      setLoading(false);
      return;
    }
  }

  const claimFundFromICO = async () => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }
    if (account !== ethers.utils.getAddress(item.admin)) {
      toast.error("You are not admin!");
      return;
    }
    setLoading(true);
    try {
      const signData: ClaimFundTokenFromICOSign = await apiPostClaimFundTokenFromICO(item._id);
      if (signData) {
        const bSuccess = await scClaimFundFromICO(chainId, library.getSigner(), signData);
        setLoading(false);
        if (bSuccess) {
          toast.success("ICO Fund token claimed successfully!");
        } else {
          toast.error("ICO Fund token claim failed!");
        }
      } else {
        toast.error("Failed from Api!");
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed!");
      setLoading(false);
      return;
    }
  }

  const allowUsersToClaim = async () => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }
    if (account !== ethers.utils.getAddress(item.admin)) {
      toast.error("You are not admin of this ICO!");
      return;
    }
    const timestamp = Date.now() / 1000;
    const signData = await library.getSigner().signMessage(arrayify(hashMessage(`${account}-${timestamp}-${item._id}`)));

    setLoading(true);
    try {
      const bSuccess = await apiPostAllowUsersToClaim(signData, account, timestamp, item._id);
      setLoading(false);
      if (bSuccess) {
        toast.success("ICO is allowed to users claim successfully!");
      } else {
        toast.error("Failed from Api!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed!");
      setLoading(false);
      return;
    }
  }

  const contributeToICO = async (amount: number) => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }
    setLoading(true);
    setIsProccessing(true)
    try {
      const signData: ContributeToICOSign = await apiPostContributeToICO(item._id, amount, account);
      if (signData) {
        const bSuccess = await scContributeToICO(chainId, library.getSigner(), account, signData);
        setLoading(false);
        setIsProccessing(false)
        if (bSuccess) {
          toast.success("Contribution Successful!");
        } else {
          toast.error("Contribution failed!");
        }
      } else {
        toast.error("Failed from Api!");
        setLoading(false);
        setIsProccessing(false)
        return;
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed!");
      setLoading(false);
      setIsProccessing(false)
      return;
    }
  }

  const claimFromICO = async () => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }
    setLoading(true);
    try {
      const signData: ClaimFromICOSign = await apiPostClaimFromICO(item._id, account);

      if (signData) {
        const bSuccess = await scClaimFromICO(chainId, library.getSigner(), signData);
        setLoading(false);
        if (bSuccess) {
          toast.success("Claim successfully!");
        } else {
          toast.error("Claim failed!");
        }
      } else {
        toast.error("Failed from Api!");
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed!");
      setLoading(false);
      return;
    }
  }

  const refundFromICO = async () => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }
    setLoading(true);
    try {
      const signData: RefundFromICOSign = await apiPostRefundFromICO(item._id, account);
      if (signData) {
        const bSuccess = await scRefundFromICO(chainId, library.getSigner(), signData);
        setLoading(false);
        if (bSuccess) {
          toast.success("Refunded successfully!");
        } else {
          toast.error("Refund failed!");
        }
      } else {
        toast.error("Failed from Api!");
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed!");
      setLoading(false);
      return;
    }
  }

  const onExitModal = async () => {
    setDepositModal(false);
    setContributeModal(false);
    setIsProccessing(false)
    setShowWhitelist(false)
    setDepositAmount(0);
    setContributeAmount(0);
  }

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      toast.success("Copied Address!");
    } catch (err) {
      toast.error("Failed to copy!");
    }
  };

  //------------------------------------
  const [showModal, setShowModal] = useState(false);
  const [logo, setLogo] = useState<File | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [tierToken, setTierToken] = useState('')
  const [tierValid, setTierValid] = useState(true)

  const [tierList, setTierList] = useState<TierData[]>([])

  const [minPerUser, setMinPerUser] = useState(0)
  const [maxPerUser, setMaxPerUser] = useState(0)

  const [price, setPrice] = useState(0)
  const [startAt, setStartAt] = useState(0)
  const [endAt, setEndAt] = useState(0)
  const [hardcap, setHardcap] = useState(0)
  const [softcap, setSoftcap] = useState(0)
  const [enableWhitelist, setEnableWhitelist] = useState<boolean>(false)
  const [whitelistCSV, setWhitelistCSV] = useState<File | null>(null);

  function addEmpty() {
    const tier: TierData = {
      tierCriteria: 0,
      tierPrice: 0,
      minPerUser: 0,
      maxPerUser: 0,
    }
    setTierList([...tierList, ...[tier]])
  }

  function onAddTier() {
    if (tierList.length > 0) {
      if (tierList[tierList.length - 1]?.tierCriteria === 0
        || tierList[tierList.length - 1]?.tierPrice === 0
        || tierList[tierList.length - 1]?.minPerUser === 0
        || tierList[tierList.length - 1]?.maxPerUser === 0) {
        toast.error(`Please fill all tier fields`);
        setTierValid(false);
        return;
      }
      if (tierList.length === 1) {
        if (price <= tierList[0]?.tierPrice) {
          toast.error("Tier price must be smaller than public price!");
          setLoading(false)
          setTierValid(false);
          return;
        }
      } else {
        if (tierList[tierList.length - 2]?.tierCriteria >= tierList[tierList.length - 1]?.tierCriteria) {
          toast.error("Tier criteria should be increased!");
          setTierValid(false);
          return;
        }
        if (tierList[tierList.length - 2]?.tierPrice <= tierList[tierList.length - 1]?.tierPrice) {
          toast.error("Tier price should be decreased!");
          setTierValid(false);
          return;
        }
      }
    }
    setTierValid(true);
    addEmpty()
  }

  useEffect(() => {
    if (item?.tiers && item?.tiers.length !== 0) {
      setTierList(item?.tiers)
    }

    if (item?.startAt && item?.startAt !== 0) {
      setStartAt(item?.startAt)
    }
    if (item?.endAt && item?.endAt !== 0) {
      setEndAt(item?.endAt)
    }

    setEnableWhitelist(item?.enableWhitelist)

  }, [item]);

  function onClear() {
    setLogo(null);
    setTitle("");
    setDescription("");
    setTierToken('')
    setPrice(0)
    setStartAt(item?.startAt)
    setEndAt(item?.endAt)
    setHardcap(0)
    setSoftcap(0)
    setMinPerUser(0)
    setMaxPerUser(0)
    setIsProccessing(false)
  }
  async function onSubmit() {
    setLoading(true)
    setIsProccessing(true)

    if (!loginStatus) {
      toast.error("Please connect wallet!");
      setLoading(false)
      return;
    }

    if (price === -1 || startAt === 0 || endAt === 0 || hardcap === -1 || softcap === -1 || minPerUser === 0 || maxPerUser === 0) {
      toast.error("Please enter correct Data");
      setLoading(false)
      return;
    }

    if (tierList.length > 0) {
      if (tierList[tierList.length - 1]?.tierCriteria === 0 || tierList[tierList.length - 1]?.tierPrice === 0
        || tierList[tierList.length - 1]?.minPerUser === 0 || tierList[tierList.length - 1]?.maxPerUser === 0) {
        toast.error(`Please fill all tier fields`);
        setTierValid(false);
        setLoading(false)
        return;
      }

      if (tierList.length === 1) {
        if (price <= tierList[0]?.tierPrice) {
          toast.error("Tier price must be smaller than public price!");
          setLoading(false)
          setTierValid(false);
          return;
        }
      } else {
        if (tierList[tierList.length - 2]?.tierCriteria >= tierList[tierList.length - 1]?.tierCriteria) {
          toast.error("Tier criteria should be increased!");
          setLoading(false)
          setTierValid(false);
          return;
        }
        if (tierList[tierList.length - 2]?.tierPrice <= tierList[tierList.length - 1]?.tierPrice) {
          toast.error("Tier price should be decreased!");
          setLoading(false)
          setTierValid(false);
          return;
        }
      }
    }

    try {
      const timestamp = Date.now() / 1000;
      const signData = await library.getSigner().signMessage(arrayify(hashMessage(`${account}-${timestamp}-${item._id}`)));
      const createNewIco = await apiPostUpdateICO(signData, account, timestamp, item._id, logo, title, description, price, startAt, endAt, hardcap, softcap, minPerUser, maxPerUser, tierToken, tierList, enableWhitelist);

      if (createNewIco !== false) {
        toast.success('Project Updated Successfully');
        setLoading(false)
        onClear();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error('Project Updating Failed!');
        setLoading(false)
      }

      setIsProccessing(false)

    } catch (error) {
      toast.error("Project Updating Failed.");
      console.error(error);
    }
    setLoading(false)
  }

  async function onSubmitWhitelist() {
    setLoading(true)
    setIsProccessing(true)

    if (!loginStatus) {
      toast.error("Please connect wallet!");
      setLoading(false)
      return;
    }

    if (!whitelistCSV) {
      toast.error("Please select whitelist csv file.");
      setLoading(false)
      return;
    }

    try {
      const timestamp = Date.now() / 1000;
      const signData = await library.getSigner().signMessage(arrayify(hashMessage(`${account}-${timestamp}-${item._id}`)));
      const isUpdatedWhitelist = await apiPostUpdateICOWhitelist(signData, account, timestamp, item._id, whitelistCSV);

      if (isUpdatedWhitelist !== false) {
        toast.success('Whitelist Updated Successfully');
        setLoading(false)
      } else {
        toast.error('Whitelist Updating Failed!');
        setLoading(false)
      }

      setIsProccessing(false)

    } catch (error) {
      toast.error("Whitelist Updating Failed.");
      console.error(error);
    }
    setLoading(false)
  }

  const onChangeNFTAsset = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setLogo(files[0]);
    }
  };

  const onChangeWhiteList = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setWhitelistCSV(files[0]);
    }
  };

  const onOpenSubmitModal = async () => {
    if (!loginStatus) {
      toast.error("Please connect wallet!");
      return;
    }
    onClear();
    setShowModal(true);
  }

  const onExitSubmitModal = async () => {
    onClear();
    setShowModal(false);
  }

  return (
    <>
      <div className={classes.root}>
        <div className={`${classes.content}`} style={{ backgroundImage: `url("/assets/hero-bg.svg")` }}>
          {!item ? <Loader /> :
            <div className={classes.wrapper}>
              <div className={classes.left}>
                <div className={classes.type}>
                  <span className={item?.status.toLocaleLowerCase()}>{item?.status}</span>
                  {item?.enableWhitelist === true && <span className={'white'}>{'WHITELISTED'}</span>}
                </div>
                <p className='regis_str'>{uiText.register} {item?.status}</p>
                <div className="line"></div>
                <h3><img src="/assets/icons/icon_star.svg" alt="" /> {uiText.REQUIREMENTS}</h3>
                <div className={classes.row}>
                  <p>{item?.tierTokenSymbol} BALANCE:</p>
                  <h5>{tierTokenBalance}</h5>
                </div>
                <div className="line"></div>
                <h3><img src="/assets/icons/icon_star.svg" alt="" /> CONTRIBUTION</h3>
                <div className={classes.row}>
                  <p>FUNDED AMOUNT:</p>
                  <h5>{userInfoForICO?.fundsAmt || 0.00} {item?.fundTokenSymbol}</h5>
                </div>
                <div className="divider"></div>
                <div className={classes.row}>
                  <p>ALLOCATION AMOUNT:</p>
                  <h5>{userInfoForICO?.tokensAmt || 0.00} {item?.icoTokenSymbol}</h5>
                </div>
                {
                  item?.admin?.toLowerCase() === account?.toLowerCase() &&
                  <>
                    <div className="line"></div>
                    <h3><img src="/assets/icons/icon_white.svg" alt="" /> WHITELIST</h3>
                    <div className={classes.btns} style = {{marginTop : 0}}>
                    <FilledButton label={'View Whitelist'} color='grey' handleClick={()=>setShowWhitelist(true)} />
                    </div>
                    <div className={classes.row}>
                      <UploadCSV accept='CSV' onChange={onChangeWhiteList} />
                    </div>
                    <div className={classes.btns}>
                    <FilledButton label={'Submit Whitelist'} color='secondary' handleClick={() => onSubmitWhitelist()} />
                    </div>

                  </>
                }

                <div className="line"></div>
                <h6>{uiText.having.split(uiText.link)[0]} <a href="http://t.me/yasha_dao_official" target={'_blank'} rel="noreferrer">{uiText.link}</a>{uiText.having.split(uiText.link)[1]}</h6>

                {!account || item?.admin.toLocaleLowerCase() !== account.toLocaleLowerCase() ?
                  <div className={classes.btns}>
                    {item?.status === 'opened' &&
                      <FilledButton label={'Contribute'} color='success' handleClick={() => setContributeModal(true)} />}
                    {item?.status === 'userclaimable' &&
                      <FilledButton label={'Claim'} color='secondary' handleClick={claimFromICO} />}
                    {item?.status === 'cancelled' &&
                      <FilledButton label={'Refund'} handleClick={refundFromICO} />}
                  </div> :
                  <div className={classes.btns}>
                    {item?.status === 'opened' &&
                      <>
                        <FilledButton label={'Deposit'} color='success' handleClick={() => setDepositModal(true)} />
                        <FilledButton label={'Complete'} color='secondary' handleClick={completeICO} />
                        <FilledButton label={'Cancel'} handleClick={cancelICO} />
                      </>
                    }
                    {item?.status === 'completed' &&
                      <FilledButton label={'Claim Fund'} color='grey' handleClick={claimFundFromICO} />}
                    {item?.status === 'claimed' &&
                      <FilledButton label={'Allow Claim'} color='success' handleClick={allowUsersToClaim} />}
                  </div>}

              </div>

              <div className={classes.right}>
                <div className={classes.top}>
                  <div className='img-div'>
                    <img src={item?.logo !== 'null' ? `${baseUploadUrl}/${item?.logo}` : '/assets/icons/icon_coin.png'} alt="" />
                    <div className='name'>
                      <h5>{item?.name}</h5>
                      <h6>{isMobileOrTablet ? truncateWalletString(item?.icoToken) : item?.icoToken}</h6>
                    </div>
                  </div>
                  {
                    item?.admin?.toLowerCase() === account?.toLowerCase() &&
                    <div className={classes.editBtn}>
                      <FilledButton label={'Edit Project'} color='success' handleClick={onOpenSubmitModal} />
                    </div>
                  }
                </div>
                <p className={classes.desription}>{item?.description}</p>
                <div className="line"></div>
                <div className="links">
                  <a href="/" target={'_blank'} rel="noreferrer">
                    <img src="/assets/icons/icon_instagram_01.svg" alt="" />
                  </a>
                  <a href="/" target={'_blank'} rel="noreferrer">
                    <img src="/assets/icons/icon_telegram_02.svg" alt="" />
                  </a>

                  <a href="/" target={'_blank'} rel="noreferrer">
                    <img src="/assets/icons/icon_global.svg" alt="" />
                  </a>

                  <a href="/" target={'_blank'} rel="noreferrer">
                    <img src="/assets/icons/icon_twitter_01.svg" alt="" />
                  </a>
                </div>

                <h2><img src="/assets/icons/icon_pool.svg" alt="" /> {uiText.pool}</h2>
                <ul>
                  <li><p>{uiText.price}:</p>   <h5>{item?.price} {item?.fundTokenSymbol}</h5></li>
                  <li><p>{uiText.total_raised}:</p>   <h5>{item?.filledFundsAmt} {item?.fundTokenSymbol}</h5></li>
                  <li><p>{uiText.soft}:</p>   <h5>{item?.softcap === 0 ? "N/A" : `${item?.softcap} ${item?.fundTokenSymbol}`}</h5></li>
                  <li><p>{uiText.hard}:</p>   <h5>{item?.hardcap === 0 ? "N/A" : `${item?.hardcap} ${item?.fundTokenSymbol}`}</h5></li>
                  <li><p>{uiText.minUser}:</p>   <h5>{item?.minPerUser === 0 ? "N/A" : `${item?.minPerUser} ${item?.fundTokenSymbol}`}</h5></li>
                  <li><p>{uiText.maxUser}:</p>   <h5>{item?.maxPerUser === 0 ? "N/A" : `${item?.maxPerUser} ${item?.fundTokenSymbol}`}</h5></li>
                  <li><p>{uiText.start}</p>   <h5>{item?.startAt === 0 ? 'TBA' : `${moment(item?.startAt * 1000).format("MMM,DD,YYYY hh:mm a")}`}</h5></li>
                  <li><p>{uiText.TGE_ends}:</p>   <h5>{item?.endAt === 0 ? 'TBA' : `${moment(item?.endAt * 1000).format("MMM,DD,YYYY hh:mm a")}`}</h5></li>

                </ul>

                <h2><img src="/assets/icons/icon_circles.svg" alt="" /> {uiText.token}</h2>

                <ul>
                  <li><p>{uiText.token}:</p>  <h5>{item?.icoTokenName}</h5></li>
                  <li><p>{uiText.type}:</p>   <h5>{item?.icoTokenSymbol}</h5></li>
                  <li><p>{uiText.supply}:</p>   <h5>{totalSupply}</h5></li>
                  <li><p>{uiText.decimal}:</p>   <h5>{item?.icoTokenDecimal}</h5></li>
                </ul>

                {item?.tierToken && item?.tierTokenName && item?.tierTokenSymbol && item?.tierTokenDecimal &&(
                  <>
                    <h2><img src="/assets/icons/icon_tier.svg" alt="" /> {uiText.tier}</h2>
                    <ul>
                      <li><p>Tier Token:</p> <h5>{truncateWalletString(item?.tierToken)}</h5>
                        <button onClick={() => copyToClipBoard(account)}><i className="fas fa-copy"></i></button></li>
                      <li><p>Tier Token Name:</p>   <h5>{item?.tierTokenName}</h5></li>
                      <li><p>Tier Token Symbol:</p>   <h5>{item?.tierTokenSymbol}</h5></li>
                      <li><p>Tier Token Decimal:</p>   <h5>{item?.tierTokenDecimal}</h5></li>
                    </ul>
                  </>
                )}

                {item?.tiers && item?.tiers.length !== 0 &&
                  <>
                    {item?.tiers.map((d, k) => (
                      <ul key={k} className='border-bottom'>
                        <li><p>{`Tier ${k + 1} Criteria`}:</p>   <h5>{d?.tierCriteria}</h5></li>
                        <li><p>Price:</p>   <h5>{d?.tierPrice}</h5></li>
                        <li><p>Min. Amount/User:</p>   <h5>{d?.minPerUser}</h5></li>
                        <li><p>Max. Amount/User:</p>   <h5>{d?.maxPerUser}</h5></li>
                      </ul>
                    ))}
                  </>
                }
              </div>
            </div>}
        </div>

        <Faqs />
      </div>

      <Modal
        show={depositModal}
        contentClass={classes.modalContent}
        children={
          <>
            <div className={classes.myModal}>
              <div className="top">
                <h2>Deposit ICO Token</h2>
                <button className="closeBtn" onClick={onExitModal}><i className="fas fa-times"></i></button>
              </div>
              <div className="content">

                <div className="row">
                  <TextInput label={'Deposit Amount'} onChangeData={(e) => setDepositAmount(parseFloat(e))} error={isProccessing && (depositAmount === 0)} wrapperClass={classes.inputWrap} />

                </div>
                <div className="row">
                  <FilledButton label={'Deposit ICO TOKEN'} color='secondary' handleClick={() => depositICOToken(depositAmount)} />
                </div>
              </div>
            </div>
          </>}
      />

      <Modal
        show={contributeModal}
        contentClass={classes.modalContent}
        children={
          <>
            <div className={classes.myModal}>
              <div className="top">
                <h2>Contribute to ICO</h2>
                <button className="closeBtn" onClick={onExitModal}><i className="fas fa-times"></i></button>
              </div>
              <div className="content">

                <div className="row">
                  <TextInput label={'Amount'} onChangeData={(e) => setContributeAmount(parseFloat(e))} error={isProccessing && (contributeAmount === 0)} wrapperClass={classes.inputWrap} />

                </div>
                <div className="row">
                  <FilledButton label={'Contribute to ICO'} color='success' handleClick={() => contributeToICO(contributeAmount)} />
                </div>
              </div>
            </div>
          </>}
      />

    <Modal
        show={showWhitelist}
        contentClass={classes.modalContent}
        children={
          <>
            <div className={classes.myModal}>
              <div className="top">
                <h2>Wthielist</h2>
                <button className="closeBtn" onClick={onExitModal}><i className="fas fa-times"></i></button>
              </div>
              <div className="content">
                <WhiteListTable list = {whitelist}/>
              </div>
            </div>
          </>}
      />

      <Modal
        show={showModal}
        contentClass={classes.modalContent}
        contentClassName={classes.contentClassName}
        maxWidth='md'
        children={
          <>
            <div className={classes.editModal}>
              <div className="top">
                <h2>Edit a project</h2>
                <button className="closeBtn" onClick={onExitSubmitModal}><i className="fas fa-times"></i></button>
              </div>
              <div className="content">

                <div className="row col-mob">

                  <div className="label  w-100 mb-5">
                    <UploadFile
                      label="Upload"
                      dispalyAsset
                      defaultAsset={item?.logo !== 'null' ? `${baseUploadUrl}/${item?.logo}` : '/assets/icons/icon_coin.png'}
                      defaultAssetType="Image"
                      fileName={logo?.name}
                      fileSize={logo?.size.toString()}
                      onChange={onChangeNFTAsset}
                    />
                  </div>
                  <div className="col w-100">

                    <div className="row">
                      <TextInput label={'Project Title'} onChangeData={setTitle} error={isProccessing && title === ''} wrapperClass={classes.inputWrap} value={title || item?.name} />
                    </div>
                    <div className="row">
                      <TextInput label={'Description'} isMulti row={4} onChangeData={setDescription} error={isProccessing && description === ''} wrapperClass={classes.inputWrap} value={description || item?.description} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <TextInput label={'Price'} onChangeData={(e) => setPrice(parseFloat(e))} error={isProccessing && price === 0} value={price || item?.price} />
                </div>

                <div className="row">
                  <TextInput label={'Tier Token'}
                    onChangeData={setTierToken}
                    // error={isProccessing && tierToken === ''}
                    value={tierToken || item?.tierToken}
                  />
                </div>
                <div className="row">
                  <div className="label">Tier Levels</div>
                  <div className="col">

                    {tierList.map((item, k) => (
                      <TierInputCard key={k}
                        item={item}
                        index={k}
                        tierValid={tierValid}
                        tierList={tierList}
                        setTierList={setTierList}
                        setTierValid={setTierValid}
                      />
                    ))}
                    <button className='addBtn' onClick={onAddTier}><i className="fas fa-plus" /> Add Tier</button>
                  </div>

                </div>

                <div className="row">
                  <div className="label">Start Time</div>
                  <div className="col">
                    <DateTimePickerField
                      value={startAt * 1000 || item?.startAt * 1000}
                      onChange={(e) => setStartAt(new Date(e).getTime() / 1000)}
                      error={isProccessing && startAt === 0} />

                  </div>
                </div>
                <div className="row">
                  <div className="label">End Time</div>
                  <div className="col">
                    <DateTimePickerField
                      value={endAt * 1000 || item?.endAt * 1000}
                      onChange={(e) => setEndAt(new Date(e).getTime() / 1000)}
                      error={isProccessing && endAt === 0} />
                  </div>
                </div>
                <div className="row">
                  <TextInput label={'Hardcap'} onChangeData={(e) => setHardcap(parseInt(e))} error={isProccessing && hardcap === 0} value={hardcap || item?.hardcap} />
                </div>
                <div className="row">
                  <TextInput label={'Softcap'} onChangeData={(e) => setSoftcap(parseInt(e))} error={isProccessing && softcap === 0} value={softcap || item?.softcap} />
                </div>
                <div className="row">
                  <TextInput label={'Min. Amount/User'} onChangeData={(e) => setMinPerUser(parseFloat(e))} error={(isProccessing && minPerUser === 0) || (minPerUser !== 0 && minPerUser >= maxPerUser)} value={minPerUser || item?.minPerUser} />

                </div>
                <div className="row alert">
                  <div className="col">
                    <ErrorAlert description={'Min. Amount/User should be smaller than Max. Amount/User!'} show={minPerUser !== 0 && minPerUser >= maxPerUser} />
                  </div>
                </div>
                <div className="row">
                  <TextInput label={'Max. Amount/User'} onChangeData={(e) => setMaxPerUser(parseFloat(e))} error={(isProccessing && maxPerUser === 0) || (maxPerUser !== 0 && minPerUser >= maxPerUser)} value={maxPerUser || item?.maxPerUser} />
                </div>
                <div className="row alert">
                  <div className="col">
                    <ErrorAlert description={'Max. Amount/User should be higher than Min. Amount/User!'} show={maxPerUser !== 0 && minPerUser >= maxPerUser} />
                  </div>
                </div>
                <div className="row">
                  <div className="label">Enable Whitelist</div>
                  <div className="col">
                    <MySwitch onChange={(e)=>setEnableWhitelist(e)} value = {enableWhitelist}/>
                  </div>
                </div>
                <div className="row">
                  <FilledButton label={'Update'} color='secondary' handleClick={onSubmit}/>
                </div>
              </div>
            </div>
          </>}
      />
    </>
  );
};

export default ProductDetail;
