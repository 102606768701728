import React, { forwardRef, Ref, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

interface FileInputProps {
  info?: string;
  label?: string;
  fileName ?:string;
  fileSize ?:string;
  onChange?: Function;
  accept?: 'CSV';
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {},

  dropzone: {
    height: 60,
    width: '100%',
    background: '#ffffffff00',
    borderRadius: 10,
    margin: 'auto',
    position: 'relative',
    textAlign: 'center',
    display : 'flex',
    alignItems : 'center',
    [theme.breakpoints.down('xs')]: {
      height: 60,
    },
    '&:hover': {
      '& $fileButton': {
        opacity: 1,
      },
    },

    '& span': {
      width: 'calc(100% - 140px)',
      maxWidth : 210,
      display : 'flex',
      flexDirection: 'column',
      '& h5': {
        width: '100%',
        color: '#636569',
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace :'nowrap',
        textAlign: 'left',
      },
    },

  },
  icon: {
    color: '#fff',
    marginLeft: 0,
  },
  fileOverlay: {
    position: 'absolute',
    width: '100%',
    height : '100%',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& h4': {
      color: '#aaa',
      fontWeight: 400,
      textAlign: 'center',
    },
    '& h5': {
      color: '#aaa',
      fontWeight: 400,
      textAlign: 'left',
    },

  },
  fileButton: {
    backgroundColor: '#F7F9FA00',
    padding: 10,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: 0.3,
    fontSize : 20,
    width : '100%',
    '&:active': {
      transform: 'scale(0.9)',
    },
  },
  input: {
    display: 'none',
  },

  [theme.breakpoints.down('xs')]: {
    width: 100,
    height: 100,
  },
}));

const UploadCSV = forwardRef<Ref<any>, FileInputProps>(
  (
    {
      accept = 'All',
      onChange,
    },
    ref,
  ) => {
    const [fileAsset, setFileAsset] = useState<string>();
    //console.log(fileAssetType, fileAsset);
    const classes = useStyles();

    function getAcceptType(accept) {
      switch (accept) {
        case 'CSV':
          return '.csv';
        case 'All':
          return '.csv';
      }
      return '';
    }

    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {

      onChange && onChange(e);
      console.log(e.target.files[0]);
      e.target.files.length > 0 && setFileAsset(e.target.files[0].name);
    };


    return (
      <div className={`${classes.dropzone} dropzone`}>
        <div className={classes.fileOverlay}>
          <label className={clsx(classes.fileButton)}>
            <i className={clsx("fas fa-file-csv", classes.icon)}></i>
            <input className={classes.input} type="file" accept={getAcceptType(accept)} onChange={onChangeFile} onClick={(event:any)=> {
               event.target.value = null
          }}/>
          { fileAsset && <h4>{fileAsset}</h4>}
          { !fileAsset && <h5>Upload CSV File</h5>}
          </label>
          
        </div>

      </div>
    );
  },
);
export default UploadCSV;
