import ErrorAlert from 'components/Widgets/ErrorAlert';
import { useState } from 'react';
import { TierData } from 'utils/api';
import { useStyles } from './style';

interface PropsType {
  index ?:number;
  item?: TierData;
  onClick?: any;
  tierValid?:boolean
  setTierValid?:any
  onRemoveTier?:any
  tierList:TierData[]
  setTierList?:any
  
}

const TierInputCard = ({ index, item, tierList, setTierList, tierValid, setTierValid }: PropsType) => {
  const classes = useStyles();
  const [tierCriteriaValid, setTierCriteriaValid] = useState(false)
  const [tierPriceValid, settierPriceValid] = useState(false)
  
  const [minPerUserValid, setMinPerUserValid] = useState(false)
  const [maxPerUserValid, setMaxPerUserValid] = useState(false)

  function onChangeTier(tier:TierData) {
    let newList = tierList;
    newList[index] = tier;
    console.log(index, newList)
    setTierList([...newList])
  }

  function onChangeTierCriteria(e:any) {
    
    setTierCriteriaValid(isNaN(parseFloat(e.target.value)))
    setTierValid(true)
    const tier : TierData = {
      tierCriteria : parseFloat(e.target.value),
      tierPrice: tierList[index]?.tierPrice,
      minPerUser: tierList[index]?.minPerUser,
      maxPerUser: tierList[index]?.maxPerUser,
    }
    onChangeTier(tier)
  }
  function onChangeTierPrice(e:any) {
    settierPriceValid(isNaN(parseFloat(e.target.value)))
    setTierValid(true)

    const tier : TierData = {
      tierCriteria : tierList[index]?.tierCriteria,
      tierPrice: parseFloat(e.target.value),
      minPerUser: tierList[index]?.minPerUser,
      maxPerUser: tierList[index]?.maxPerUser,
    }
    onChangeTier(tier)
  }
  function onChangeMinPerUser(e:any) {
    setMinPerUserValid(isNaN(parseFloat(e.target.value)))
    setTierValid(true)

    const tier : TierData = {
      tierCriteria : tierList[index]?.tierCriteria,
      tierPrice: tierList[index]?.tierPrice,
      minPerUser: parseFloat(e.target.value),
      maxPerUser: tierList[index]?.maxPerUser,
    }
    onChangeTier(tier)
  }
  function onChangeMaxPerUser(e:any) {
    setMaxPerUserValid(isNaN(parseFloat(e.target.value)))
    setTierValid(true)

    const tier : TierData = {
      tierCriteria : tierList[index]?.tierCriteria,
      tierPrice: tierList[index]?.tierPrice,
      minPerUser: tierList[index]?.minPerUser,
      maxPerUser: parseFloat(e.target.value),
    }
    onChangeTier(tier)
  }
  function onRemove() {
    const filter = tierList.filter((item, k) => k !== index )
    setTierList(filter)
    setTierValid(true)
  }

  return (
    <div className={classes.root}>
      <ul>
        <li>
          <h3>{`Tier ${index + 1} Criteria`}</h3>
          
          <input 
            className={`myInput ${(!tierValid) || tierCriteriaValid ? 'error':''}`}  
            type='number' 
            onChange = {onChangeTierCriteria} 
            value = {tierList[index].tierCriteria} 
          />
        </li>
        <li>
          <h3>Price</h3>
          <input 
            className={`myInput ${(!tierValid) || tierPriceValid ? 'error':''}`}  
            type='number' 
            onChange = {onChangeTierPrice} 
            value = {tierList[index]?.tierPrice} 
          />
        </li>
        <li>

          <h3>Min. Amount/User</h3>
          <input 
            className={`myInput ${(!tierValid) || minPerUserValid ? 'error':''}`}  
            type='number' 
            onChange = {onChangeMinPerUser} 
            value = {tierList[index]?.minPerUser} 
          />
        </li>
        <li>
          <h3>Max. Amount/User</h3>
          <input 
            className={`myInput ${(!tierValid) || maxPerUserValid ? 'error':''}`}  
            type='number' 
            onChange = {onChangeMaxPerUser} 
            value = {tierList[index]?.maxPerUser} 
          />
        </li>
        {tierList[index]?.minPerUser!==0 && tierList[index]?.minPerUser>=tierList[index]?.maxPerUser &&
        <li style={{width : '100%'}}>
        <ErrorAlert description={'Min. Amount/User should be smaller than Max. Amount/User!'} show={tierList[index]?.minPerUser!==0 && tierList[index]?.minPerUser>=tierList[index]?.maxPerUser}/>
        </li>}
      </ul>
      
      <button className='closeBtn' onClick={onRemove} ><i className="fas fa-times"/></button>
    </div>
  );
};

export default TierInputCard;
