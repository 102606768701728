import { useEffect } from "react"
import { ConnectorLocalStorageKey, ConnectorNames } from "config";
import useAuth from 'hooks/useAuth'

export function useEagerConnect() {
  const { login } = useAuth();

  useEffect(() => {
    let connectorId = window.localStorage.getItem(
      ConnectorLocalStorageKey
    ) as ConnectorNames;
    if (connectorId) {
      login(connectorId);
    }
  }, [login]); // intentionally only running on mount (make sure it's only mounted once :))
}
